import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../layouts/main'
import HeaderMeta from '../components/HeaderMeta'
import ProgressiveBgImage from '../components/ProgressiveBgImage'

class Article extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext
    const siteAddress = 'https://www.bluemonday.se'

    return (
      <Layout location={this.props.location} title={post.frontmatter.title}>
        <ProgressiveBgImage
          hqImage="/images/office-desk.jpg"
          lqImage="/images/office-desk-lq.jpg"
        >
          <article className="article">
            <HeaderMeta
              title={post.frontmatter.title}
              description={post.frontmatter.description || post.excerpt}
              canonical={`${siteAddress}${post.fields.slug}`}
            />
            <section className="section content-box dirty_white">
              <img
                className="section_image"
                src={post.frontmatter.featured_image}
                alt={post.frontmatter.title}
              />
              <div dangerouslySetInnerHTML={{ __html: post.html }} />

              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0
                }}
              >
                <li>
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev">
                      ← {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link to={next.fields.slug} rel="next">
                      {next.frontmatter.title} →
                    </Link>
                  )}
                </li>
              </ul>
            </section>
          </article>
        </ProgressiveBgImage>
      </Layout>
    )
  }
}

export default Article

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        excerpt
        featured_image
      }
    }
  }
`
